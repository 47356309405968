import { Column, Container, Row } from '@algbra/ui/components/Grid/Grid'
import Heading from '@algbra/ui/components/Heading/Heading'
import Whitespace from '@algbra/ui/components/Whitespace/Whitespace'
import { map } from 'rambdax'
import React from 'react'
import ZoneProductFeatureBox, { ProductFeatureBox } from './ProductFeatureBox'
import styles from './ProductFeatureBoxList.module.scss'

export type ZoneProductFeatureBoxListProps = {
  __typename: 'StrapiGQL_ComponentZonesProductFeatureBoxList'
  title: string
  features: ProductFeatureBox[]
}
export default function ZoneProductFeatureBoxList({
  title,
  features,
}: ZoneProductFeatureBoxListProps): JSX.Element {
  return (
    <Whitespace type="lg">
      <Container>
        <Heading type="md" className={styles.title}>
          {title}
        </Heading>
        <Row>
          {map(
            feature => (
              <Column size={12} md={6} key={`feat-${feature.id}`}>
                <ZoneProductFeatureBox feature={feature} />
              </Column>
            ),
            features
          )}
        </Row>
      </Container>
    </Whitespace>
  )
}
