import { graphql, navigate } from 'gatsby'
import { map } from 'rambdax'
import PageLayout from '../components/PageLayout/PageLayout'
import SubpageHeader from '../components/SubpageHeader/SubpageHeader'
import { getMetaTags } from '../utils/metaTags'
import ZoneInlinePressLogoList, {
  ZoneInlinePressLogoListProps,
} from '../zones/InlinePressLogoList/InlinePressLogoList'
import ZoneProductFeatureBoxList, {
  ZoneProductFeatureBoxListProps,
} from '../zones/ProductFeatureBoxList/ProductFeatureBoxList'
import { ReactComponent as AppStoreIcon } from '../images/referrals/app-store.svg'
import { ReactComponent as PlayStoreIcon } from '../images/referrals/play-store.svg'
import { ReactComponent as QrCodeIcon } from '../images/referrals/universal-qr.svg'
import styles from './referralssuccess.module.scss'
import { StaticImage } from 'gatsby-plugin-image'
import { Container } from '@algbra/ui/components/Grid/Grid'
import Button from '@algbra/ui/components/Button/Button'
import Icon from '@algbra/ui/components/Icon/Icon'
import { getIconByName } from '@algbra/ui/icons'

export type ZoneContentTypes =
  | ZoneInlinePressLogoListProps
  | ZoneProductFeatureBoxListProps

export default function LayoutReferral({
  pageContext,
  data,
  location,
  params,
}) {
  const zones: ZoneContentTypes[] = data.strapiGQL.page.contentZones

  // setTimeout(() => {
  //   navigate('https://app.algbra.com/download')
  // }, 3000)

  return (
    <PageLayout simpleHeader={true}>
      <Icon
        icon={getIconByName('success')}
        fill="#00D68F"
        size={60}
        className={styles.success}
      />
      <SubpageHeader
        title={pageContext.title}
        summary={pageContext.subtitle}
        metaTags={getMetaTags(pageContext)}
        className={styles.header}
      />

      <Container className={styles.root}>
        <StaticImage
          src="../../images/referrals/card-pink.png"
          alt="Algbra Card"
          layout="constrained"
          width={852}
          quality={100}
          placeholder="blurred"
          loading="eager"
          className={styles.cardPink}
        />
        <StaticImage
          src="../../images/referrals/card-black.png"
          alt="Algbra Card"
          layout="constrained"
          width={715}
          quality={100}
          placeholder="blurred"
          loading="eager"
          className={styles.cardBlack}
        />
      </Container>

      <Container>
        <div className={styles.container}>
          <div className={styles.downloadTheAppContainer}>
            <div className={styles.qrIcon}>
              <QrCodeIcon width={200} />
            </div>
            <Button
              className={styles.downloadButton}
              size="normal"
              onClick={() => navigate('/download')}
            >
              Download Now
            </Button>

            <div className={styles.stepList}>
              <div className={styles.stepItem}>
                <div className={styles.bullet}>
                  <span>1</span>
                </div>
                <p>Scan QR Code and download Algbra app.</p>
              </div>
              <div className={styles.stepItem}>
                <div className={styles.bullet}>
                  <span>2</span>
                </div>
                <div>
                  <p>Create your free Algbra account in minutes.</p>
                  <p className={styles.finePrint}>
                    We just need valid ID documents like your
                    <strong> passport or driver's license</strong>.
                  </p>
                </div>
              </div>
              <div className={styles.stepItem}>
                <div className={styles.bullet}>
                  <span>3</span>
                </div>
                <p>Once approved, your account is ready to go!</p>
              </div>
            </div>

            <div className={styles.qrDesc}>Can't scan the QR code?</div>

            <div className={styles.appStoreIcons}>
              <div
                onClick={() => {
                  window.open(
                    'https://apps.apple.com/app/algbra-ethical-finance/id1610109395'
                  )
                }}
              >
                <AppStoreIcon />
              </div>
              <div
                onClick={() => {
                  window.open(
                    'https://play.google.com/store/apps/details?id=com.algbra.Banking'
                  )
                }}
              >
                <PlayStoreIcon />
              </div>
            </div>
          </div>
        </div>

        {map(item => {
          switch (item.__typename) {
            case 'StrapiGQL_ComponentZonesPressLogoList':
              return <ZoneInlinePressLogoList key={item.id} {...item} />
            case 'StrapiGQL_ComponentZonesProductFeatureBoxList':
              return <ZoneProductFeatureBoxList key={item.id} {...item} />
            default:
              return null
          }
        }, zones)}
      </Container>
    </PageLayout>
  )
}

export const pageQuery = graphql`
  query ($id: ID!) {
    strapiGQL {
      page(id: $id) {
        slug
        title
        subtitle
        contentZones {
          __typename
          ... on StrapiGQL_ComponentZonesProductFeatureBoxList {
            id
            title
            features {
              id
              title
              color
              body
              contentAlignment
              isAvailable
              image {
                id
                url
                ext
                resolved {
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED
                      width: 555
                      formats: WEBP
                      webpOptions: { quality: 100 }
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
          }
          ... on StrapiGQL_ComponentZonesPressLogoList {
            id
            title
            list {
              image {
                id
                url
                ext
                width
                height
                resolved {
                  publicURL
                }
              }
              alternativeText
              id
            }
          }
        }
      }
    }
  }
`
