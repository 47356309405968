import { graphql, useStaticQuery } from 'gatsby'
import { find, last, pluck, split } from 'rambdax'
import { Helmet } from 'react-helmet'
import { SocialLink } from '../../types/footer'
import { PageMetaTags } from '../../utils/metaTags'
import { SnippetFinancialProduct } from '../../utils/seo-snippets'

export default function MetaTags(props: PageMetaTags) {
  const {
    strapiGQL: { navigationFooter },
  } = useStaticQuery(graphql`
    query {
      strapiGQL {
        navigationFooter(publicationState: LIVE) {
          socialLinks {
            id
            title
            iconType
            url
          }
        }
      }
    }
  `)
  const socialLinks = navigationFooter.socialLinks as SocialLink[]
  const financialProductSnippet = SnippetFinancialProduct({
    sameAs: navigationFooter ? pluck('url', socialLinks) : [],
  })
  const twitter = find(link => link.iconType === 'twitter', socialLinks)
  const twitterHandle = split('twitter.com/', twitter.url)

  return (
    <Helmet defer={false} script={[financialProductSnippet]}>
      <title>{props.title}</title>
      <meta name="description" content={props.description} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content={twitter.url} />
      <meta name="twitter:title" content={props.title} />
      <meta name="twitter:description" content={props.description} />
      <meta name="twitter:creator" content={last(twitterHandle)} />
      <meta name="twitter:image" content={props.image} />
      <meta property="og:title" content={props.title} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={props.image} />
      <meta property="og:image:secure_url" content={props.image} />
      <meta property="og:description" content={props.description} />
      <meta property="og:site_name" content={props.siteName} />
      <link rel="dns-prefetch" href="//apps.apple.com" />
      <link rel="dns-prefetch" href="//play.google.com" />
      <link rel="dns-prefetch" href="//www.googletagmanager.com" />
      <link rel="dns-prefetch" href="//www.google-analytics.com" />
      <link rel="dns-prefetch" href="//consent.cookiebot.com﻿" />
      <link rel="dns-prefetch" href="//consentcdn.cookiebot.com﻿" />
      <link rel="dns-prefetch" href="//cdn.algbra.com" />
      <link rel="dns-prefetch" href="//cdnjs.cloudflare.com" />
      <link rel="dns-prefetch" href="//static.hotjar.com" />
      <link rel="dns-prefetch" href="//www.googleadservices.com" />
      <link rel="dns-prefetch" href="//googleads.g.doubleclick.net" />
      <link rel="dns-prefetch" href="//connect.facebook.net" />
      <link rel="dns-prefetch" href="//analytics.twitter.com" />
      <link rel="dns-prefetch" href="//static.ads-twitter.com" />
      <link rel="dns-prefetch" href="//snap.licdn.com" />
    </Helmet>
  )
}
