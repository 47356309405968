import { getSrc, ImageDataLike } from 'gatsby-plugin-image'
import { removeTags } from './removeTags'

export type SocialLink = {
  handle: string
  url: string
}

export type SEOFields = {
  title?: string
  description?: string
  image?: {
    resolved: ImageDataLike
  }
}
export type PageContextType = {
  id: string
  title: string
  subtitle: string
  slug: string
  siteName: string
  slogan: string
  siteUrl: string
  description: string
  seo: SEOFields
}

export type PageMetaTags = {
  title: string
  description: string
  siteName: string
  image: string
}

export function getMetaTags(ctx: PageContextType): PageMetaTags {
  const title = ctx.seo?.title || ctx.title
  const description = ctx.seo?.description || removeTags(ctx.subtitle || ctx.description)
  const image = ctx.seo?.image
    ? `${ctx.siteUrl}${getSrc(ctx.seo.image.resolved)}`
    : `${ctx.siteUrl}/images/og-banner.png`
  return {
    siteName: ctx.siteName,
    title: `${title} - ${ctx.siteName}`,
    description: description,
    image,
  }
}
