import AnnouncementBar, {
  AnnouncementBarProps,
} from '@algbra/ui/components/AnnouncementBar/AnnouncementBar'
import { ThemeContext } from '@algbra/ui/enums/theme'
import classNames from 'classnames'
import React, { ReactNode, useState } from 'react'
import Footer from '../Footer/Footer'
import Header from '../Header/Header'
import styles from './PageLayout.module.scss'

export type PageLayoutProps = {
  children: ReactNode
  theme?:
    | 'light'
    | 'dark'
    | 'darkTranslucent'
    | 'chaimama'
    | 'carbonTracking'
    | 'roadShow'
  announcement?: AnnouncementBarProps
  simpleHeader?: boolean
}

const PageLayout = React.forwardRef<HTMLDivElement, PageLayoutProps>(
  (props, ref) => {
    const { theme = 'light', children, announcement, simpleHeader } = props
    const [themeName, setThemeName] = useState<string>(theme)
    const setTheme = value => {
      setThemeName(value)
      return value
    }
    return (
      <ThemeContext.Provider value={{ theme: themeName, setTheme }}>
        <div id="portal"></div>
        <div
          ref={ref}
          className={classNames(styles.pageLayout, styles[`theme__${theme}`])}
        >
          <Header hasBorder={true} simple={simpleHeader} />
          <AnnouncementBar
            title="SC Ventures makes strategic investment in Algbra, establishes partnership with its UK sustainable finance platform Shoal"
            to="https://www.algbra.com/news/sc-ventures-makes-strategic-investment-in-algbra-establishes-partnership-with-its-uk-sustainable-finance-platform-shoal/"
            target="_blank"
          />
          <div className={styles.pageLayoutContent}>{children}</div>
          <Footer />
        </div>
      </ThemeContext.Provider>
    )
  }
)

export default PageLayout
