import FreeText from '@algbra/ui/components/FreeText/FreeText'
import Heading from '@algbra/ui/components/Heading/Heading'
import Icon from '@algbra/ui/components/Icon/Icon'
import Permalink from '@algbra/ui/components/Permalink/Permalink'
import { getIconByName } from '@algbra/ui/icons'
import { resolveAsImage } from '@algbra/ui/resolvers'
import { GatsbyResolvedAsset } from '@algbra/ui/types/image'
import classNames from 'classnames'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import styles from './ProductFeatureBox.module.scss'

export type ProductFeatureBox = {
  id: string
  title: string
  color: string
  body: string
  contentAlignment: string
  isAvailable: boolean
  image?: GatsbyResolvedAsset
  linkUrl?: string
  linkTitle?: string
  linkTarget?: string
  isAvailableBadgeBgColor?: string
  isAvailableBadgeTextColor?: string
  description_logo?: GatsbyResolvedAsset
}
export type ZoneProductFeatureBoxProps = {
  feature: ProductFeatureBox
}
export default function ZoneProductFeatureBox({
  feature,
}: ZoneProductFeatureBoxProps): JSX.Element {
  const {
    id,
    title,
    color,
    body,
    contentAlignment,
    isAvailable,
    image,
    linkTitle,
    linkUrl,
    linkTarget,
    isAvailableBadgeBgColor,
    isAvailableBadgeTextColor,
    description_logo,
  } = feature
  const isBlank = linkTarget === 'new'
  return (
    <div
      className={classNames(
        styles.container,
        styles.color,
        styles[`color__${color}`],
        styles.alignment,
        styles[`alignment__${contentAlignment}`]
      )}
    >
      <div className={styles.contentOver}>
        {!isAvailable && (
          <span
            className={classNames(
              styles.availableSoon,
              styles[`badgeColor__${isAvailableBadgeTextColor}`]
            )}
            style={{
              backgroundColor: isAvailableBadgeBgColor
                ? isAvailableBadgeBgColor
                : undefined,
            }}
          >
            Available Soon
          </span>
        )}
        <Heading type="lg" className={styles.title}>
          {title}
        </Heading>
        <div className={styles.body}>
          <FreeText type="md" innerHTML={body} className={styles.text}>
            {body}
          </FreeText>
        </div>
        {description_logo && (
          <GatsbyImage image={resolveAsImage(description_logo)} alt="" />
        )}

        {linkTitle && (
          <FreeText type="m" className={styles.detailLink}>
            <Permalink to={linkUrl} isExternal={isBlank} isBlank={isBlank}>
              {linkTitle}
            </Permalink>
            <Icon icon={getIconByName('arrow-right')} size={24} />
          </FreeText>
        )}
      </div>
      <GatsbyImage
        image={resolveAsImage(image)}
        className={styles.bgImage}
        alt=""
      />
    </div>
  )
}
