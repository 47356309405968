import { helmetJsonLdProp } from 'react-schemaorg'
import { FinancialProduct } from 'schema-dts'

export const SnippetFinancialProduct = (others: Partial<FinancialProduct>) =>
  helmetJsonLdProp<FinancialProduct>({
    '@context': 'https://schema.org',
    '@type': 'BankAccount',
    name: 'Algbra',
    ...others,
  })
